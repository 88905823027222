.hero-wraper {
    background: url(../../../public/assets/slider-bg.png);
    width: 100%;
    height: 120vh;
    background-size: cover;
    background-position: center bottom;
    /* display: flex;
    align-items: center;*/
    text-align: left; 
    padding-top: 15% ;
}
.hero-wraper h1 {
    color: white;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    margin: 2rem 0;
    /* identical to box height */
}
.hero-wraper a {
    margin: 10px;
}
.hero-wraper a img {
    width: 30px;    
}

@media only screen and (max-width: 991px) {
    #home .logo {
        width: 250px;
    }
    .hero-wraper {
        padding-top: 40%;
    }
    .navbar-toggler {
        background-color:white;
    }
}