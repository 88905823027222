.about-wraper h2 {
    font-size: 24px;
    font-weight: 700;
    color: #4B50D0;
    background:url(../../../public/assets/about-title.png);
    background-size: 100%;
    padding: 1.5rem 3rem;
    display: inline-block;
    background-repeat: no-repeat;
    line-height: 70px;
}
.about-wraper p {
    text-align:justify;
    color: #16005C;
}
#about, .h-5rm {
    height: 5rem;
}

.about-wraper .owl-theme .owl-dots {margin-top: 5rem !important;}
.about-wraper .owl-theme .owl-dots .owl-dot span {background-color: #D9D9F6;width: 21px;height: 21px;}
.about-wraper .owl-theme .owl-dots .owl-dot.active span {
    background-color: #4B50D0;
}
