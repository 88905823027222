.navbar-nav .nav-link {
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;}
.navbar-nav .nav-link.active {
    color: #EBFF00;
}
.navbar-nav .nav-link:focus {
    color: #EBFF00;
}
.navbar.bg {
    background: rgba(28, 20, 94, 0.2);
    padding: 1rem 0;
}

@media only screen and (max-width: 991px) {
    .navbar.bg {
        padding: 18px;
    }
    .navbar-toggler:focus, .navbar-toggler:hover {
        outline: none;
        box-shadow: none;
    }
    .navbar-brand {
        position: absolute;
        right: 0;
        top: 18px;
    }
    .navbar.bg {
        background-color: #4b50d0;
    }
    .navbar-nav {
        margin-top: 1rem;
    }
}
/* .navbar.bg {background: rgb(28 20 94 / 87%);} */