.title {
    font-size: 35px;
    font-weight: 700;
    color: #4B50D0;
    background:url(../../../public/assets/about-title.png);
    background-size: 100%;
    padding: 2.5rem 3rem;
    display: inline-block;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
    .sponsers-container .d-flex  {
        flex-direction: column;
    }
    .sponsers-container .d-flex .logo {
        margin-bottom: 1rem;
    }
    .logo img {
        width: 100%;
        height: auto;
    }
}
