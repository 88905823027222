footer {
    background: url(../../../public/assets/footerbg.svg);
    width: 100%;
    height: 630px;
    background-size: cover;
    background-position: center top;
    padding-top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

}
footer img {
    margin: 0 15px;
}
.sponsorbtn {
    display: block;
    margin-top: 4rem;
}
.sponsorbtn a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #4B50D0;
    padding: .7rem 5rem;
    border-radius: 50px;
    background: #FFFFFF;
    text-decoration: none;
}
.sponsorbtn a:hover {
    background: #d4ec87;
    color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
    footer img.logo {
        width: 300px;
    }
    footer img {
        width: 34px;
    }
}