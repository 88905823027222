.team { 
    background: url(../../../public/assets/carosel-bg.png);
    width: 100%;
    background-size: 100% auto;
    background-position: center bottom;
    /* display: flex;
    align-items: center;*/
    text-align: left; 
    padding: 7% 0 10%;
}
.team h2 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    line-height: 96px;
    color: #FFFFFF;
}
.team .owl-item {
    background: #D4EC87;
}
.team .owl-carousel .owl-item {
    padding: 3rem;
}
.team .owl-carousel .owl-item img {
    text-align: center;
    width: 100%;
    max-width: inherit;
    margin: 20px auto;
}
.owl-item h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 48px;
    color: #4B50D0;
    padding: 0 20px;
    margin-bottom: 0;
    text-align: center;
}
.owl-item h4 {
    padding: 0 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 48px;    
    color: #4B50D0;text-align: center;
    font-size: 20px;
}

.team .owl-carousel .owl-item::before {
    content: '';
    display: inline-block;
    background-image:url(../../../public/assets/stars-top.svg);
    width: 31.67px;
    height: 71.85px;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 15px;
    top: 15px;
}
.team .owl-carousel .owl-item::after {
    content: '';
    display: inline-block;
    background-image:url(../../../public/assets/stars-bottom.svg);
    background-size: 100%;
    width: 31.67px;
    height: 71.85px;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    bottom: 15px;
}
.team .owl-theme .owl-dots .owl-dot span {background-color: #FFF;width: 21px;height: 21px;}
.team .owl-theme .owl-dots .owl-dot.active span {
    background-color: #1C145E;
}
.team .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 2rem;
}

@media only screen and (max-width: 767px) {
    .team {
        padding: 25% 0 25%;
        background-size: cover;
    }
}